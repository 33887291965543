import { Authority } from '@/shared/security/authority';

const Login = () => import('@/account/login/login.vue');
const Account = () => import('@/account/account.vue');

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: { authorities: [Authority.ADMIN, Authority.USER] },
  },
];
