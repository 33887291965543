import axios from 'axios';

import { IDeliveryBilling } from '@/shared/model/delivery-billing.model';
import { IBillingParamsSingle } from '@/shared/model/billing-params-single.model';
import { IBillingParamsMultiple } from '@/shared/model/billing-params-multiple.model';

const baseApiUrl = 'api/delivery-billings';

export default class DeliveryService {
  public find(id: number): Promise<IDeliveryBilling> {
    return new Promise<IDeliveryBilling>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(stockId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/stock/${stockId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IDeliveryBilling): Promise<IDeliveryBilling> {
    return new Promise<IDeliveryBilling>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IDeliveryBilling): Promise<IDeliveryBilling> {
    return new Promise<IDeliveryBilling>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public transfer(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/transfer/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
