import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ICustomer } from '@/shared/model/customer.model';
import { ICustomerSuggestion } from '@/shared/model/customer-suggestion.model';

const baseApiUrl = 'api/customers';

export default class CustomerService {
  public find(id: number): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any, blacklisted?: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?blacklisted=${blacklisted || false}&${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public suggestCustomer(name: string): Promise<ICustomerSuggestion[]> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/suggestion/${name}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getMaxCustomer(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/max-customer`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: ICustomer): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: ICustomer): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
