var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-snackbar",
    {
      attrs: { color: "transparent", elevation: "0", timeout: "-1" },
      model: {
        value: _vm.snackbar,
        callback: function ($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar",
      },
    },
    _vm._l(_vm.snackbarContent, function (alert, i) {
      return _c(
        "v-alert",
        {
          key: i,
          attrs: {
            border: "left",
            color:
              (alert.type === "SUCCESS" && "success") ||
              (alert.type === "WARNING" && "warning") ||
              (alert.type === "ERROR" && "error") ||
              "",
            type:
              (alert.type === "SUCCESS" && "success") ||
              (alert.type === "WARNING" && "warning") ||
              (alert.type === "ERROR" && "error") ||
              "",
            dismissible: "",
          },
          model: {
            value: alert.show,
            callback: function ($$v) {
              _vm.$set(alert, "show", $$v)
            },
            expression: "alert.show",
          },
        },
        [_vm._v("\n    " + _vm._s(alert.content) + "\n  ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }