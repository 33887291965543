import { PromptDialogContent } from '@/shared/model/prompt-dialog-content.model';
import { AppStore } from './types';

export const state: AppStore = {
  snackbar: false,
  snackbarContent: [],
  promptDialog: false,
  promptDialogContent: new PromptDialogContent(null, null, null, null),
  redButtonActive: false,
  resolve: null,
  reject: null,
};
