import { Component, Inject, Vue } from 'vue-property-decorator';
import OrderService from '@/order/order.service';
import { IAlert } from '@/shared/model/alert.model';
import AccountService from '@/account/account.service';

@Component
export default class AppNavbar extends Vue {
  @Inject('orderService') private orderService: () => OrderService;
  @Inject('accountService') private accountService: () => AccountService;
  private hasAdminAuthorityValue = false;

  private toHomePage() {
    this.$route.fullPath !== '/ptv-export' && this.$router.push('/ptv-export');
  }

  changeTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  public get hasAdminAuthority(): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth('ROLE_ADMIN')
      .then(value => {
        this.hasAdminAuthorityValue = value;
      });
    return this.hasAdminAuthorityValue;
  }

  private get redButtonActive(): boolean {
    return this.$store.getters['appStore/getRedButtonActive'];
  }
  private set redButtonActive(newStatus: boolean) {
    this.$store.dispatch('appStore/setRedButtonActive', newStatus);
  }

  private redButton() {
    if (this.redButtonActive === undefined || this.redButtonActive === null) {
      this.orderService().checkForNewOrders();
      this.redButtonActive = false;
    }

    this.redButtonActive = !this.redButtonActive;
    this.orderService().redButton(this.redButtonActive);
  }

  private toggleDrawer() {
    this.$emit('toggleDrawer');
  }

  private closeDrawer() {
    this.$emit('closeDrawer');
  }
}
