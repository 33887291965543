import { IAlert } from '@/shared/model/alert.model';
import { PromptDialogContent } from '@/shared/model/prompt-dialog-content.model';
import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { AppStore } from './types';

export const actions: ActionTree<AppStore, RootState> = {
  setSnackbar({ commit }, newSnackbar: boolean): void {
    commit('setSnackbar', newSnackbar);
  },
  setSnackbarContent({ commit }, newSnackbarContent: IAlert[]): void {
    commit('setSnackbarContent', newSnackbarContent);
  },
  setPromptDialog({ commit }, newPromptDialog: boolean): void {
    commit('setPromptDialog', newPromptDialog);
  },
  setPromptDialogContent({ commit }, newPromptDialogContent: PromptDialogContent): void {
    commit('setPromptDialogContent', newPromptDialogContent);
  },
  promptDialog({ commit }): Promise<boolean> {
    commit('setPromptDialog', true);
    return new Promise((resolve, reject) => {
      commit('setResolve', resolve);
      commit('setReject', reject);
    });
  },
  setRedButtonActive({ commit }, newRedButtonActive: boolean): void {
    commit('setRedButtonActive', newRedButtonActive);
  },
  resolve({ state, commit }) {
    state.resolve(true);
    commit('setPromptDialog', false);
  },
  reject({ state, commit }) {
    state.reject(false);
    commit('setPromptDialog', false);
  },
};
