import axios from 'axios';
import { IBillingJournalList } from '@/shared/model/billing-journal-list.model';

const baseApiUrl = 'api';

export default class BillingsJournalService {
  public reportBillingJournal(fromDate: Date, toDate: Date): Promise<IBillingJournalList> {
    return new Promise<IBillingJournalList>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/report/billing-journal`, { fromDate, toDate })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public exportBillingJournalToExcel(fromDate: Date, toDate: Date) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/report/export-billing-journal`, { fromDate, toDate }, { responseType: 'blob' })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
          link.href = url;
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteBillingJournal(orderId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/orders/billing-journal/${orderId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
