import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class PromptDialog extends Vue {
  private get promptDialog(): boolean {
    return this.$store.getters['appStore/getPromptDialog'];
  }
  private set promptDialog(newPromptDialog: boolean) {
    this.$store.dispatch('appStore/setPromptDialog', newPromptDialog);
  }
  private get promptDialogContent() {
    return this.$store.getters['appStore/getPromptDialogContent'];
  }
  private set promptDialogContent(newPromptDialogContent) {
    this.$store.dispatch('appStore/setPromptDialogContent', newPromptDialogContent);
  }

  private resolve(): void {
    this.$store.dispatch('appStore/resolve');
  }

  private reject(): void {
    this.$store.dispatch('appStore/reject');
  }
}
