import axios from 'axios';
import { IOrderList } from '@/shared/model/order-list.model';

const baseApiUrl = 'api';

export default class DeletedOrdersService {
  public reportDeletedOrders(finallyDeleted: boolean): Promise<IOrderList[]> {
    const deleteEndpoint = finallyDeleted ? `${baseApiUrl}/orders/finally-deleted` : `${baseApiUrl}/orders/deleted`;

    return new Promise<IOrderList[]>((resolve, reject) => {
      axios
        .get(deleteEndpoint)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public restoreDeletedOrder(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/orders/restore-deleted/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
