import { IAlert } from '@/shared/model/alert.model';
import { PromptDialogContent } from '@/shared/model/prompt-dialog-content.model';
import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { AppStore } from './types';

export const getters: GetterTree<AppStore, RootState> = {
  getSnackbar(state): boolean {
    return state.snackbar;
  },
  getSnackbarContent(state): IAlert[] {
    return state.snackbarContent;
  },
  getPromptDialog(state): boolean {
    return state.promptDialog;
  },
  getPromptDialogContent(state): PromptDialogContent {
    return state.promptDialogContent;
  },
  getRedButtonActive(state): boolean {
    return state.redButtonActive;
  },
};
