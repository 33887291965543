import { IAlert } from '@/shared/model/alert.model';
import { PromptDialogContent } from '@/shared/model/prompt-dialog-content.model';
import { MutationTree } from 'vuex';
import { AppStore } from './types';

export const mutations: MutationTree<AppStore> = {
  setSnackbar(state, newSnackbar: boolean): void {
    state.snackbar = newSnackbar;
  },
  setSnackbarContent(state, newSnackbarContent: IAlert[]): void {
    state.snackbarContent = newSnackbarContent;
  },
  setPromptDialog(state, newPromptDialog: boolean): void {
    state.promptDialog = newPromptDialog;
  },
  setPromptDialogContent(state, newPromptDialogContent: PromptDialogContent): void {
    state.promptDialogContent = newPromptDialogContent;
  },
  setRedButtonActive(state, newRedButtonActive: boolean): void {
    state.redButtonActive = newRedButtonActive;
  },
  setResolve(state, newResolve): void {
    state.resolve = newResolve;
  },
  setReject(state, newReject): void {
    state.reject = newReject;
  },
};
