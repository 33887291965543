import axios from 'axios';
import { IAudit } from '@/shared/model/audit.model';

const baseApiUrl = 'api';

export default class ActivitiesService {
  public queryAudits(startDate: Date, endDate: Date): Promise<IAudit[]> {
    return new Promise<IAudit[]>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/audit/list`, { startDate, endDate })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
