var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "30rem" },
      model: {
        value: _vm.promptDialog,
        callback: function ($$v) {
          _vm.promptDialog = $$v
        },
        expression: "promptDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-4", attrs: { rounded: "xl" } },
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(" " + _vm._s(_vm.promptDialogContent.title) + " "),
          ]),
          _vm._v(" "),
          _c("v-card-text", [
            _vm._v(
              "\n      " + _vm._s(_vm.promptDialogContent.text) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                { attrs: { text: "" }, on: { click: _vm.reject } },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-close")]),
                  _vm._v(" " + _vm._s(_vm.promptDialogContent.reject) + " "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.resolve },
                },
                [
                  _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-check")]),
                  _vm._v(" " + _vm._s(_vm.promptDialogContent.resolve)),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }