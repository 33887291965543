export interface IPromptDialogContent {
  title: string;
  text: string;
  resolve: string;
  reject: string;
}

export class PromptDialogContent implements IPromptDialogContent {
  constructor(public title: string, public text: string, public resolve: string, public reject: string) {}
}
