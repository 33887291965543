import Vue from 'vue';
import dayjs from 'dayjs';
import { IAddress } from '@/shared/model/address.model';
import { ICustomer } from '@/shared/model/customer.model';
import { ITruck } from '@/shared/model/truck.model';
import 'dayjs/locale/de';
import JhiDataUtils from '@/shared/data/data-utils.service';

export const DATE_FORMAT_TEXT = 'DD.MM.YYYY';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const MONTH_FORMAT = 'MMMM YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';

export const DATE_TIME_LONG_FORMAT = 'DD.MM.YYYYTHH:mm';
const dataUtils = new JhiDataUtils();

export function initFilters() {
  Vue.filter('formatJustDateText', value => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT_TEXT);
    }
    return '';
  });
  Vue.filter('formatJustTime', value => {
    if (value) {
      return dayjs(value).format(TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('formatJustDate', value => {
    if (value) {
      return dayjs(value).format(DATE_FORMAT);
    }
    return '';
  });
  Vue.filter('formatMonth', value => {
    if (value) {
      return dayjs(value).locale('de').format(MONTH_FORMAT);
    }
    return '';
  });
  Vue.filter('formatDate', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('formatMillis', value => {
    if (value) {
      return dayjs(value).format(DATE_TIME_FORMAT);
    }
    return '';
  });
  Vue.filter('duration', value => {
    if (value) {
      const formatted = dayjs.duration(value).humanize();
      if (formatted) {
        return formatted;
      }
      return value;
    }
    return '';
  });
  Vue.filter('formatPrice4', value => {
    if (value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 4,
      }).format(value);
    }
    return '';
  });
  Vue.filter('formatNumber', value => {
    if (value) {
      return new Intl.NumberFormat('de-DE', {}).format(value);
    }
    return '';
  });
  Vue.filter('formatLiter', value => {
    if (value) {
      return new Intl.NumberFormat('de-DE', {}).format(value) + ' Liter';
    }
    return '';
  });
  Vue.filter('formatLiterWithZero', value => {
    if (!value) value = 0;

    return new Intl.NumberFormat('de-DE', {}).format(value) + ' Liter';
  });
  Vue.filter('formatPrice5', value => {
    if (value) {
      return new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 5,
      }).format(value);
    }
    return '';
  });
  Vue.filter('formatPrice', value => {
    if (value) {
      value = dataUtils.round(value);
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', useGrouping: true }).format(value);
    }
    return '';
  });
  Vue.filter('formatPriceWithSign', value => {
    if (value) {
      value = dataUtils.round(value);
      return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', useGrouping: true }).format(value);
    }
    return '0 €';
  });
  Vue.filter('formatPriceWithZero', value => {
    if (!value) value = 0;

    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', useGrouping: true }).format(value);
  });
  Vue.filter('formatJustPrice', value => {
    if (value) {
      return new Intl.NumberFormat('de-DE').format(value);
    }
    return '';
  });
  Vue.filter('formatPriceWithoutRound', value => {
    if (value) {
      return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 10 }).format(value);
    }
    return '0';
  });
  Vue.filter('formatAddress', value => {
    if (value) {
      const address: IAddress = value;
      return formatString(address.street) + ', ' + formatString(address.zipcode) + ' ' + formatString(address.city);
    }
    return '';
  });
  Vue.filter('formatBillingAddress', value => {
    if (value) {
      const address: IAddress = value;
      return formatString(address.street) + '<br />' + formatString(address.zipcode) + ' ' + formatString(address.city);
    }
    return '';
  });
  Vue.filter('formatCustomer', value => {
    if (value) {
      const customer: ICustomer = value;
      return formatString(customer.title) + '<br />' + formatString(customer.firstName) + ' ' + formatString(customer.lastName);
    }
    return '';
  });
  Vue.filter('formatCustomer2', value => {
    if (value) {
      const customer: ICustomer = value;
      return formatString(customer.title) + ' ' + formatString(customer.firstName) + ' ' + formatString(customer.lastName);
    }
    return '';
  });
  Vue.filter('formatDistance', value => {
    if (value) {
      const distance: number = value;
      return new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(distance);
    }
    return '0,0';
  });
  Vue.filter('formatTruck', value => {
    if (value) {
      const truck: ITruck = value;
      return truck.name + ' (' + truck.numberPlate + ')';
    }
    return '';
  });

  const formatString = (str: string) => (str ? str : '');
}
